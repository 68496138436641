<template>
  <container-broadcast-settings
    ref="userDeviceSettings"
    #default="{ audioDevices, videoDevices, resolutions, pending, error }"
    :disabled="!isReadyOnAir"
    leading
    conference-type
  >
    <broadcast-panel
      ref="broadcastPanel"
      v-fullscreen.attach="'broadcast'"
      :popup="$mqLaptop || ($fullscreen.isFullscreen && $fullscreen.group === 'broadcast')"
      :expanded="showBroadcastSettingsPopup"
      :expand-blocked="!isReadyOnAir"
      :loading="(!isReadyOnAir || pending || isPlayerFirstLoading) && !error.type"
      @opening="openBroadcastPopup"
      @closing="closeBroadcastPopup"
    >
      <template #header>
        <room-broadcast-panel-header
          :can-present="permissions.canPresent"
          :allow-on-air="isReadyOnAir"
          :on-air="onAirAsLeading"
          :hand-up-disabled="handsUpBtnDisabled"
          @close="closeBroadcastPopup"
          @ready-on-air="handleReadyOnAir"
          @hand-up="handUp"
        />
      </template>
      <template #player>
        <room-broadcast-preview-player
          v-if="isReadyOnAir && !pending && !error.type"
          :on-air="onAirAsLeading"
          :resolution="resolution"
          :constraints="broadcastPlayerConstraints"
          @loading="isPlayerLoading = true"
          @loaded="handlePlayerLoaded"
          @error="handlePreviewError"
        />
      </template>
      <template #controls>
        <broadcast-controls
          :audio-devices="audioDevices"
          :video-devices="videoDevices"
          :pending="pending"
          :on-air="onAirAsLeading"
          :blocked="!!error.type || !showBroadcastSettingsPopup || isPlayerLoading"
          :go-on-air-blocked="previewError"
          :resolutions="resolutions"
          leading
          prepared
          @set-audio="setSettings('Audio', $event)"
          @set-video="setSettings('Video', $event)"
          @set-resolution="setSettings('resolution', $event)"
          @go-on-air="startUsersTranslation"
          @exit-from-on-air="stopUsersTranslation"
        />
      </template>
    </broadcast-panel>
  </container-broadcast-settings>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { auth, broadcast } from "@/store/modules/store.namespaces";
import { RAISE_HAND } from "@/store/modules/broadcast/action-types";
import { SPEAKER_PLAYER_CONSTRAINTS } from "@/store/modules/broadcast/getter-types";
import { BROADCAST_SET_STATE_PROP } from "@/store/modules/broadcast/mutation-types";
import { OPTION_EXTERNAL_STREAM } from "@/constants/broadcast/broadcast-const";

import RoomBroadcastPreviewPlayer from "@/components/room/broadcast/RoomBroadcastPreviewPlayer";
import ContainerBroadcastSettings from "@/containers/broadcast/ContainerBroadcastSettings";
import BroadcastControls from "@/components/common/broadcast/BroadcastControls";
import BroadcastPanel from "@/components/common/broadcast/BroadcastPanel";
import RoomBroadcastPanelHeader from "@/components/room/broadcast/RoomBroadcastPanelHeader";

export default {
  name: "ContainerRoomBroadcast",
  components: {
    RoomBroadcastPanelHeader,
    BroadcastPanel,
    ContainerBroadcastSettings,
    BroadcastControls,
    RoomBroadcastPreviewPlayer,
  },
  data() {
    return {
      isReadyOnAir: false,
      isPlayerLoading: false,
      isPlayerFirstLoading: true,
      previewError: false,
    };
  },
  computed: {
    ...mapState(auth, ["user"]),
    ...mapState(broadcast, [
      "resolution",
      "handsUpBtnDisabled",
      "onAir",
      "permissions",
      "onAirAsLeading",
      "showBroadcastSettingsPopup",
    ]),
    ...mapGetters(broadcast, {
      broadcastPlayerConstraints: SPEAKER_PLAYER_CONSTRAINTS,
    }),
  },
  watch: {
    "permissions.canPresent": function(val) {
      if (!val) {
        this.isReadyOnAir = false;
        this.stopUsersTranslation();
        this.closeBroadcastPopup();
      }
    },
    showBroadcastSettingsPopup(val) {
      if (val && !this.isReadyOnAir) {
        this.handleReadyOnAir();
      }
    },
  },
  beforeDestroy() {
    this.setBroadcastStateProp({
      onAirAsLeading: false,
    });
  },
  methods: {
    ...mapMutations(broadcast, {
      setBroadcastStateProp: BROADCAST_SET_STATE_PROP,
    }),
    ...mapActions(broadcast, {
      raiseHand: RAISE_HAND,
    }),
    handlePlayerLoaded() {
      this.isPlayerLoading = false;
      this.isPlayerFirstLoading = false;
    },
    handlePreviewError() {
      this.previewError = true;
      this.handlePlayerLoaded();
    },
    async handleReadyOnAir() {
      this.isReadyOnAir = true;
      await this.$nextTick();
      await this.$refs.userDeviceSettings?.init();
      if (!this.showBroadcastSettingsPopup) {
        this.$refs.broadcastPanel.expand();
      }
    },
    async setSettings(key, value) {
      const stateKey = ["Video", "Audio"].includes(key) ? `selected${key}` : key;
      this.setBroadcastStateProp({
        [stateKey]: value,
      });

      if (key === "Video" && value.deviceId !== OPTION_EXTERNAL_STREAM) {
        await this.$refs.userDeviceSettings?.checkAvailableResolution(value);
      }
      if (key === "Audio") {
        await this.$refs.userDeviceSettings?.checkAvailableAudio(value);
      }
    },
    closeBroadcastPopup() {
      this.setBroadcastStateProp({
        showBroadcastSettingsPopup: false,
      });
    },
    openBroadcastPopup() {
      this.setBroadcastStateProp({
        showBroadcastSettingsPopup: true,
      });
    },
    startUsersTranslation() {
      this.setBroadcastStateProp({
        onAirAsLeading: true,
      });
      this.closeBroadcastPopup();
    },
    stopUsersTranslation() {
      this.setBroadcastStateProp({
        onAirAsLeading: false,
      });
    },
    async handUp() {
      const hasName = this.user.fio.trim();
      if (!hasName) {
        this.setBroadcastStateProp({
          requestUserName: true,
        });
      } else {
        await this.raiseHand();
        this.$notify({
          group: "broadcast",
          type: "success",
          duration: 5000,
          title: this.$t("broadcast.requestGoOnAirTitle"),
          text: this.$t("broadcast.requestGoOnAirText"),
        });
      }
    },
  },
};
</script>
