<template>
  <div
    :class="[
      $style.container,
      { [$style.container_popup]: $mqLaptop, [$style.container_fullscreen]: inFullscreenMode },
    ]"
  >
    <template v-if="$mqLaptop">
      <span :class="$style.title">
        {{ $t("broadcast.streamingSettings") }}
      </span>
    </template>
    <template v-else>
      <broadcast-connection-quality :quality="connectionState" />
      <ws-button v-if="inFullscreenMode" color="default" icon @click="$emit('close')">
        <ws-icon lg light>times</ws-icon>
      </ws-button>
      <ws-button
        v-else-if="!allowOnAir"
        color="primary"
        outlined
        sm
        :disabled="handUpDisabled"
        @click="clickHandler"
      >
        {{ canPresent ? $t("broadcast.goOnAir") : $t("broadcast.raiseHand") }}
      </ws-button>
    </template>
  </div>
</template>

<script>
import { ConnectionQuality } from "@/constants/broadcast/broadcast-const";
import BroadcastConnectionQuality from "@/components/common/broadcast/BroadcastConnectionQuality";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

/**
 * Шапка выхода в эфир на участнике
 *
 * @vue-prop {boolean} canPresent - Разрешение от спикера присоединиться к стриму
 * @vue-prop {boolean} allowOnAir - Разрешение выйти в эфир
 * @vue-prop {boolean} onAir - В эфире ли участник
 * @vue-prop {boolean} handUpDisabled - Рука уже поднята, отключена возможность поднятия руки
 * @vue-computed {string} connectionState - Состояние для выхода в эфир
 * @vue-event ready-on-air - Готовность к выходу в эфир
 * @vue-event hand-up - Запрос для выхода в эфир (Должен одобрить спикер)
 */

export default {
  name: "RoomBroadcastPanelHeader",
  components: { WsButton, WsIcon, BroadcastConnectionQuality },
  props: {
    canPresent: {
      type: Boolean,
      default: false,
    },
    allowOnAir: {
      type: Boolean,
      default: false,
    },
    onAir: {
      type: Boolean,
      default: false,
    },
    handUpDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    connectionState() {
      return this.onAir ? ConnectionQuality.OnAir : ConnectionQuality.Off;
    },
    inFullscreenMode() {
      return this.$fullscreen.isFullscreen && this.$fullscreen.group === "broadcast";
    },
  },
  methods: {
    clickHandler() {
      this.$emit(this.canPresent ? "ready-on-air" : "hand-up");
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 15px;
  user-select: none;

  &_popup {
    padding: 15px 0;
  }

  &_fullscreen {
    padding: 5px 0;
  }
}

.title {
  font-size: 1.4em;
  font-weight: 600;
  line-height: 1.4em;
}
</style>
