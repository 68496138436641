<template>
  <div ref="fragment">
    <!-- <room-common-notifications
      v-fullscreen.attach="['presentation', 'broadcast']"
      @close="closeNotification"
    /> -->
    <system-notifications
      ref="commonNotifications"
      v-fullscreen.attach="['presentation', 'broadcast']"
      group="notif"
      position="top right"
      @close="closeNotificationPopup"
    />
    <room-important-notifications
      v-show="importantNotifyVisible && lastSystemNotification.id"
      v-fullscreen.attach="['presentation', 'broadcast']"
      :notification="lastSystemNotification"
      @close="closeSystemNotify"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { auth, notifications, room, broadcast } from "@/store/modules/store.namespaces";
import {
  GET_LAST_NOTIFICATION,
  GET_UNREAD_NOTIFICATIONS,
} from "@/store/modules/notifications/action-types";

import SystemNotifications from "@/components/common/notifications/SystemNotifications";
import RoomImportantNotifications from "@/components/event/notification/RoomImportantNotifications";
// import RoomCommonNotifications from "@/components/event/notification/RoomCommonNotifications";

export default {
  name: "ContainerNotifications",
  components: {
    // RoomCommonNotifications,
    RoomImportantNotifications,
    SystemNotifications,
  },
  data() {
    return {
      shownNotification: null,
      importantNotifyVisible: false,
    };
  },
  computed: {
    ...mapState(notifications, ["lastSystemNotification", "lastNotification"]),
    ...mapState(room, ["roomNumber"]),
    ...mapState(auth, ["user"]),
    ...mapState(broadcast, ["onAir"]),
  },
  watch: {
    /**
     * Наблюдатель за последним уведомлением.
     * Показывает уведомление при запущенном стриме.
     *
     * @param {object} lastNotification - последнее уведомление
     */
    lastNotification(lastNotification) {
      // Если объект пустой, то была вызвана очистка всех уведомлений.
      if (Object.keys(lastNotification).length === 0) {
        const { commonNotifications } = this.$refs;
        commonNotifications?.closeAllNotifications();
      } else {
        (this.onAir || this.$fullscreen?.isFullscreen) &&
          lastNotification?.text &&
          !this.isClosedNotifPopup() &&
          this.showNotificationPopup(lastNotification);
      }
    },
    /**
     * Наблюдатель за выходом в эфир.
     * Показывает уведомление при запуске стрима.
     * Необходим для показа уведомления после перезапуска страницы.
     *
     * @param {boolean} onAir - включен ли стрим
     */
    onAir(onAir) {
      onAir &&
        !this.isClosedNotifPopup() &&
        this.lastNotification?.text &&
        this.showNotificationPopup(this.lastNotification);

      if (!onAir) {
        const { commonNotifications } = this.$refs;
        commonNotifications?.closeAllNotifications();
      }
    },
    lastSystemNotification(newNotif) {
      this.importantNotifyVisible =
        newNotif.id !== +localStorage.getItem("lastSystemNotify") && true;
    },
    roomNumber(newVal, oldVal) {
      if (newVal && oldVal) {
        this.getLastNotifications();
      }
    },
  },
  created() {
    this.getUnreadNotifs();
    this.getLastNotifications();
  },
  mounted() {
    const $fragmentParent = document.querySelector("[data-id=page]");
    const $fragmentChildren = [...this.$refs.fragment.childNodes];
    $fragmentChildren.forEach(child => {
      $fragmentParent.appendChild(child);
    });
    this.$refs.fragment.remove();
  },
  beforeDestroy() {
    this.$refs.importantNotification?.$el.remove();
    this.$refs.notification?.$el.remove();
  },
  methods: {
    ...mapActions(notifications, {
      getUnreadNotifs: GET_UNREAD_NOTIFICATIONS,
      getLastNotifications: GET_LAST_NOTIFICATION,
    }),
    closeSystemNotify(id) {
      localStorage.setItem("lastSystemNotify", id);
      this.importantNotifyVisible = false;
    },
    closeNotification(props) {
      localStorage.setItem("lastClosedNotif", this.lastNotification?.id);
      props?.close();
    },
    /**
     * Метод закрытия попапа.
     *
     * TODO: Проблема в том, что закрывается последнее уведомление, а не выбранное.
     * То есть если закрыть любое уведомление, то закроется только последнее. Поправить.
     *
     * @param {object} props - уведомление
     */
    closeNotificationPopup(props) {
      localStorage.setItem("lastClosedNotifPopup", this.lastNotification?.id);
      props?.close();
    },
    /**
     * Метод показа всплывающего уведомления.
     *
     * @param {object} notification - Уведомление
     */
    showNotificationPopup(notification) {
      this.$notify({
        group: "notif",
        title: this.$t("Notifs.notification"),
        text: notification?.text,
        type: "warn",
        duration: 5000,
      });
    },
    /**
     * Метод проверки было ли закрыто последнее всплывающее уведомление.
     *
     * TODO: Перепроверить и поправить метод, сделать ему реактивность и вынести в computed.
     *
     * @returns {boolean} Закрыто ли последнее всплывающее уведомление.
     */
    isClosedNotifPopup() {
      const lastClosedNotifPopup = localStorage.getItem("lastClosedNotifPopup") || 0;
      return this.lastNotification?.id === +lastClosedNotifPopup;
    },
  },
};
</script>
