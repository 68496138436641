<template>
  <transition name="fade">
    <div class="important-notify">
      <div class="important-notify__popup">
        <section class="important-notify__section">
          <header class="important-notify__header">
            <div class="important-notify__author">
              <div v-if="urlImg" class="important-notify__logo">
                <img :src="urlImg" />
              </div>
              <span>
                {{ notification.fio || notification.speaker }}
              </span>
            </div>
            <p class="important-notify__time">
              {{ notification.time | moment("HH:mm") }}
            </p>
          </header>

          <!-- Было getNotifyMessage(notification.text) -->
          <div class="important-notify__content" v-html="notification.text"></div>
          <footer class="important-notify__footer">
            <a
              href="/"
              class="important-notify__close"
              @click.prevent="$emit('close', notification.id)"
            >
              {{ $t("common.okText") }}
            </a>
          </footer>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import { makeUrl } from "@/utils";

export default {
  name: "RoomImportantNotifications",
  props: {
    notification: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    urlImg() {
      return this.notification.photo ? makeUrl(this.notification.photo) : false;
    },
  },
  methods: {
    getNotifyMessage(message) {
      return (
        message
          ?.toString()
          .split("\n")
          .filter(stroke => stroke.length)
          .map(item => `<p class="important-notify__text">${item}</p>`)
          .join(" ") || ""
      );
    },
  },
};
</script>

<style lang="less">
.important-notify {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5002;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--base-fade-color);
  transition: opacity 0.3s ease-in-out;

  &__popup {
    width: 40%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: -20px 0 60px 0 rgba(0, 0, 0, 0.29);
  }

  &__author {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 75px;
    padding-left: 10px;
    margin: 0 auto;
    font-size: 12px;
    color: #505050;

    span {
      display: block;
      margin-right: 23px;
    }
  }

  &__logo {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 7px;
    overflow: hidden;
    border-radius: 50%;

    & > img {
      display: inline-block;
      height: 100%;
    }
  }

  &__time {
    margin: 9px 0 0;
    font-size: 12px;
    color: #bababa;
    text-align: center;
  }

  &__content,
  &__section {
    padding: 19px 22px 22px;
    overflow: auto;
  }

  &__content {
    max-height: 50vh;

    h1,
    h2,
    h3,
    h4 {
      all: revert;
    }
  }

  &__text {
    margin: 0;
    line-height: 17px;
  }

  &__footer {
    padding: 15px 0;
  }

  &__close {
    display: block;
    width: 159px;
    padding: 4px 10px 3px;
    margin: 0 auto;
    font-size: 13px;
    color: #8f1441;
    text-align: center;
    border: 1px solid #8f1441;
    border-radius: 15.5px;
  }
}

@media (max-width: 768px) {
  .important-notify {
    &__popup {
      width: 90%;
    }
  }
}
</style>
