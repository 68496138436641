<template>
  <smart-modal
    v-fullscreen.attach="'broadcast'"
    :text-confirm="$t('common.send')"
    type="form"
    class="user-name-request"
    :class="{
      'user-name-request--fullscreen':
        $fullscreen.isFullscreen && $fullscreen.group === 'broadcast',
    }"
    @close="close"
    @cancel="close"
    @confirm="confirm"
  >
    <template #body>
      <div class="user-name-request__body">
        <h2 class="user-name-request__title" v-html="$t('broadcast.enterName')"></h2>
        <input
          ref="nameField"
          v-model="name"
          type="text"
          name="user-name"
          class="user-name-request__input"
          :class="{
            'user-name-request__input--invalid': error,
          }"
          :placeholder="$t('Registration.fio')"
        />
        <span v-show="error" class="user-name-request__error-text">{{
          $t("common.required")
        }}</span>
      </div>
    </template>
  </smart-modal>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { broadcast, auth } from "@/store/modules/store.namespaces";
import { BROADCAST_SET_STATE_PROP } from "@/store/modules/broadcast/mutation-types";
import { SET_USER_FIO } from "@/store/modules/auth/mutation-types";
import { ADD_LEADING, RAISE_HAND } from "@/store/modules/broadcast/action-types";
import SmartModal from "@/components/common/modals/SmartModal";

export default {
  name: "RoomUserNameRequestModal",
  components: {
    SmartModal,
  },
  data() {
    return {
      name: "",
      error: false,
    };
  },
  watch: {
    name() {
      if (this.error) {
        this.error = false;
      }
    },
  },
  mounted() {
    this.$refs.nameField.focus();
  },
  methods: {
    ...mapMutations(broadcast, {
      setBroadcastStateProp: BROADCAST_SET_STATE_PROP,
    }),
    ...mapMutations(auth, {
      setUserFio: SET_USER_FIO,
    }),
    ...mapActions(broadcast, {
      addLeading: ADD_LEADING,
      raiseHand: RAISE_HAND,
    }),
    close() {
      this.setBroadcastStateProp({
        requestUserName: false,
      });
    },
    async confirm() {
      if (!this.name.trim()) {
        this.error = true;
      } else {
        this.setUserFio(this.name);
        await this.addLeading();
        await this.raiseHand();
        this.$notify({
          group: "broadcast",
          type: "success",
          duration: 5000,
          title: this.$t("broadcast.requestGoOnAirTitle"),
          text: this.$t("broadcast.requestGoOnAirText"),
        });
        this.close();
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/_vars.less";

.user-name-request {
  --page-nav-height: 0;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    max-width: 70%;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }

  &__input {
    width: 100%;
    padding: 5px 10px;
    font-size: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 3px;

    &:focus,
    &:hover {
      border-color: var(--base-color);
      outline: none;
    }

    &--invalid,
    &--invalid:focus,
    &--invalid:hover {
      border-color: @ws-red;
    }
  }

  &__error-text {
    width: 100%;
    font-size: 14px;
    color: @ws-red;
  }

  &--fullscreen {
    z-index: 101;
  }
}
</style>
