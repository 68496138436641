<template>
  <room-user-menu
    :rooms="rooms"
    :user="user"
    :options="options"
    :visible="userPopupVisible"
    @logout="logoutHandler"
    @room-change="roomChangeHandler"
    @open-feedback="openFeedbackHandler"
    @open-support="openSupportHandler"
    @close="toggleMenu"
  />
</template>

<script>
import { auth, broadcast, polls, room } from "@/store/modules/store.namespaces";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { makeUrl } from "@/utils";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import { CHANGE_ROOM } from "@/store/modules/common/action-types";
import { SET_WS_POLL_MODAL_OPEN } from "@/store/modules/polls/mutation-types";
import { TOGGLE_USER_POPUP_VISIBLE } from "@/store/modules/common/mutation-types";
import { LOGOUT } from "@/store/modules/auth/action-types";
import { DELETE_LEADING } from "@/store/modules/broadcast/action-types";
import { IS_CONFERENCE_TYPE } from "@/store/modules/broadcast/getter-types";
import { SHOW_SUPPORT_MODAL } from "@/store/mutations/mutation-types";
import RoomUserMenu from "@/components/room/partials/RoomUserMenu";

const GUEST_NAMES = ["гость", "guest"];

/**
 * Бизнес логика для меню пользователя в комнате
 *
 * @typedef UserMenuOptions
 * @property {boolean} [support=false] - Модуль техподдержки
 * @property {boolean} [multilingual=false] - Модуль переключения языка
 * @property {boolean} [feedback=false] - Модуль опроса "Обратная связь"
 * @type {object}
 *
 * @typedef User
 * @property {string} [avatar=""] - аватар пользователя
 * @property {string} [fullName=""] - Полное имя пользователя
 * @property {string} [position=""] - Должность пользователя
 * @property {boolean} [guest=false] - Гость пользователь или нет
 * @type {object}
 *
 * @vue-computed {UserMenuOptions} options - опции для вывода доступных в комнате блоков управления
 * @vue-computed {Array} rooms - список комнат
 * @vue-computed {User} user - информация о пользователе
 */
export default {
  name: "ContainerRoomUserMenu",
  components: {
    RoomUserMenu,
  },
  computed: {
    ...mapState(auth, {
      storeUser: "user",
    }),
    ...mapState(room, ["roomNumber", "roomId", "userPopupVisible"]),
    ...mapState(polls, ["wsPollAvailable"]),
    ...mapGetters(room, {
      roomModules: AVAILABLE_ROOM_MODULES,
    }),
    ...mapGetters(broadcast, {
      isConferenceType: IS_CONFERENCE_TYPE,
    }),
    options() {
      return {
        support: this.roomModules.feedbackForm,
        feedback: this.wsPollAvailable && this.roomModules.aboutws_quiz,
        multilingual: this.roomModules.isShowLanguagePanel,
      };
    },
    rooms() {
      return (
        this.storeUser.room
          ?.filter(room => room.number)
          .map(room => ({
            ...room,
            logo: makeUrl(room.logo),
            selected: room.number === this.roomNumber,
          })) || []
      );
    },
    user() {
      return {
        avatar: this.storeUser.photoWithUrl || "",
        fullName: this.storeUser.fio,
        guest: this.storeUser.is_guest || GUEST_NAMES.includes(this.storeUser.fio),
        position: this.storeUser.fields?.position || "",
      };
    },
  },
  methods: {
    ...mapActions(room, {
      changeRoom: CHANGE_ROOM,
    }),
    ...mapMutations(polls, {
      openFeedbackPoll: SET_WS_POLL_MODAL_OPEN,
    }),
    ...mapMutations(room, {
      toggleMenu: TOGGLE_USER_POPUP_VISIBLE,
    }),
    ...mapActions(auth, {
      logout: LOGOUT,
    }),
    ...mapActions(broadcast, {
      deleteLeading: DELETE_LEADING,
    }),
    ...mapMutations({
      openSupportForm: SHOW_SUPPORT_MODAL,
    }),
    /**
     * Обработчик выхода из платформы, так же логирует что юзер больше не присутствует в комнате
     */
    logoutHandler() {
      if (this.storeUser.scheduleMainFilter) {
        const payload = {
          login: this.storeUser.login,
          fio: this.storeUser.fio,
          userId: this.storeUser.id,
          roomNumber: this.roomNumber,
          roomId: this.roomId,
          audienceId: this.storeUser.scheduleMainFilter,
        };
        this.$loggers.$userPresence.sendLog(
          this.$loggers.$userPresence.getPayload(payload, this.$loggers.$userPresence.Events.Stop),
        );
      }
      this.logout();
    },
    /**
     * Обработчик события смены комнаты
     *
     * @param {string} roomNumber - номер комнаты
     */
    roomChangeHandler(roomNumber) {
      if (this.isConferenceType) {
        this.deleteLeading();
      }
      this.changeRoom(roomNumber);
      localStorage.removeItem("agentIdFilter");
      localStorage.removeItem("agentId");
    },
    /**
     * Обработчик события открытия "Опрос о WhenSpeak"
     */
    openFeedbackHandler() {
      this.openFeedbackPoll(true);
    },
    /**
     * Обработчик события открытыя формы обратной связи
     */
    openSupportHandler() {
      this.openSupportForm();
    },
  },
};
</script>
