<template>
  <layout-page v-show="!isLoading" :sidebar="$mqLaptop" :sheet="showBroadcastPopup && !$mqLaptop">
    <room-container :class="$style.container" />
    <room-side-container
      v-if="showSidebar && ($mqLaptop || broadcastInFullscreenMode)"
      :class="[$style.sidebar]"
    >
      <!-- Место куда перемещается плеер когда мы на десктопе, на мобилке #PROVIDER_POSITIONS.ROOM_CONTAINER -->
      <div
        v-show="!roomPending && onAir"
        :id="$options.PROVIDER_POSITIONS.ROOM_SIDE"
        :class="[$style.sidebar__playerWrapper]"
      />

      <!-- Обычное уведомление -->
      <div
        v-if="lastNotification.text && notificationsAvailable && !onAir && !isClosedNotif"
        :class="$style.notification"
      >
        <div :class="$style.notificationHeader">
          {{ $t("Notifs.attention") }}
        </div>
        <p>{{ lastNotification.text }}</p>
      </div>
      <!-- Конец обычного уведомления -->

      <div :class="$style.features">
        <div v-if="isQuestionsAvailable || isChatAvailable" :class="$style.tabs">
          <div
            v-if="isChatAvailable"
            :class="[
              $style.tab,
              {
                [$style.tab_active]: messengerTab === 'MainChatList',
              },
            ]"
            @click="setTab('MainChatList')"
          >
            {{ this.tabNames.chatTabName }}
          </div>
          <div
            v-if="isQuestionsAvailable"
            :class="[
              $style.tab,
              {
                [$style.tab_active]: messengerTab === 'SpeakerQuestions',
              },
            ]"
            @click="setTab('SpeakerQuestions')"
          >
            {{ this.tabNames.questionsTabName }}
          </div>
        </div>
      </div>

      <messenger-wrapper>
        <chat v-if="isChatAvailable" v-show="messengerTab === 'MainChatList'" />
        <container-questions
          v-if="isQuestionsAvailable"
          v-show="messengerTab === 'SpeakerQuestions'"
          @fullscreen="isQuestionFullscreen = $event"
        />
        <div
          v-show="messengerTab === 'SpeakerQuestions' && isQuestionFullscreen"
          class="absolute-center ws-nowrap"
        >
          {{ $t("SpeakerQuestions.fullScreenQuestions") }}
        </div>
      </messenger-wrapper>
    </room-side-container>
    <container-room-broadcast v-if="showBroadcastPopup" :class="$style.broadcastSettings" />
    <room-user-name-request-modal v-if="onAir && isConferenceType" v-show="requestUserName" />
    <container-notifications v-if="notificationsAvailable" />
    <container-room-user-menu />
    <system-notifications group="roomSystem" position="bottom right" />
    <system-notifications group="broadcast" position="top right" />

    <div v-show="showInteractive" v-fullscreen.attach="['presentation', 'broadcast']">
      <interactive v-if="showInteractive" />
    </div>
    <simple-modal v-if="wsPollModalOpen" class="ws-poll">
      <div slot="body">
        <ws-poll />
      </div>
    </simple-modal>
    <event-web-socket-container />
    <user-presence-provider />
  </layout-page>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import * as roomGettersKeys from "@/store/modules/common/getter-types";
import {
  auth,
  broadcast,
  chat,
  landing,
  messenger,
  notifications,
  polls,
  questions,
  room,
} from "@/store/modules/store.namespaces";
import { interactive } from "@/constants/polls/polls-module-types";
import { SET_MESSENGER_TAB } from "@/store/modules/messenger/mutation-types";
import { ROLE_SET_MODE } from "@/store/mutations/mutation-types";
import { subscribeNotifications, removeOneSignalSubscription } from "@/services/OnesignalService";
import {
  IS_BROADCAST_ENABLED,
  IS_CONFERENCE_TYPE,
  IS_ONE_2_MANY_TYPE,
} from "@/store/modules/broadcast/getter-types";
import { SET_QUESTIONS_SORT } from "@/store/modules/questions/mutation-types";
import { RoomRouteName } from "@/constants/router/router-const";
import { POLL, POLL_SCORES } from "@/constants/polls/poll-interactive-types";

import AuthService from "@/services/AuthService";
import UserPresenceProvider from "@/components/event/user-presence/UserPresenceProvider";
import ContainerRoomBroadcast from "@/containers/broadcast/ContainerRoomBroadcast";
import RoomUserNameRequestModal from "@/components/room/broadcast/RoomUserNameRequestModal";
import ContainerNotifications from "@/containers/room/notification/ContainerNotifications";
import SystemNotifications from "@/components/common/notifications/SystemNotifications";
import LayoutPage from "@/components/layouts/LayoutPage";
import EventWebSocketContainer from "@/components/event/EventWebSocketContainer";
import ContainerRoomUserMenu from "@/containers/room/ContainerRoomUserMenu";
import RoomSideContainer from "@/components/event/RoomSideContainer";
import { PROVIDER_POSITIONS } from "@/constants/broadcast/broadcast-const";

export default {
  name: "ViewRoom",
  components: {
    EventWebSocketContainer,
    UserPresenceProvider,
    ContainerRoomBroadcast,
    RoomUserNameRequestModal,
    ContainerNotifications,
    SystemNotifications,
    LayoutPage,
    ContainerRoomUserMenu,
    RoomSideContainer,
    ContainerQuestions: () =>
      import(/* webpackChunkName="ContainerQuestions" */ "@/containers/common/ContainerQuestions"),
    RoomContainer: () =>
      import(/* webpackChunkName="RoomContainer" */ "@/components/event/RoomContainer"),
    MessengerWrapper: () =>
      import(
        /* webpackChunkName="RoomMessengerWrapper" */ "@/components/common/messenger/MessengerWrapper"
      ),
    Chat: () => import(/* webpackChunkName="RoomChat" */ "@/components/chat/Chat"),
    WsPoll: () => import(/* webpackChunkName="RoomWsPoll" */ "@/components/event/WsPoll/WsPoll"),
    SimpleModal: () =>
      import(
        /* webpackChunkName="AppSimpleModal" */ "@/components/event/layouts/modals/SimpleModal"
      ),
    Interactive: () =>
      import(
        /* webpackChunkName="Interactive" */ "@/components/event/room/Interactive/Interactive"
      ),
  },
  PROVIDER_POSITIONS,
  data() {
    return {
      isLoading: true,
      isQuestionFullscreen: false,
    };
  },
  computed: {
    ...mapGetters(room, {
      availableRoomModules: roomGettersKeys.AVAILABLE_ROOM_MODULES,
      navigationElements: roomGettersKeys.NAVIGATION_ELEMENTS,
    }),
    ...mapState(chat, ["roomChatId", "teamChatId"]),
    ...mapState(room, ["roomPending", "sidebar", "roomId"]),
    // todo fixme
    ...mapState(room, {
      room: "roomNumber",
    }),
    ...mapState(messenger, ["messengerTab"]),
    ...mapState(landing, {
      landingTemplate: "template",
    }),
    ...mapState(broadcast, ["prepared", "onAir", "requestUserName", "permissions"]),
    ...mapState(polls, ["wsPollModalOpen"]),
    ...mapState(`${polls}/${interactive}`, ["interactiveType"]),
    ...mapGetters(broadcast, {
      isBroadcastEnabled: IS_BROADCAST_ENABLED,
      isConferenceType: IS_CONFERENCE_TYPE,
      isOne2ManyType: IS_ONE_2_MANY_TYPE,
    }),
    ...mapState(notifications, ["lastNotification"]),
    ...mapState(auth, ["user"]),
    navigationItems() {
      return this.navigationElements || [];
    },
    tabNames() {
      return this.getTabNames();
    },
    showInteractive() {
      return this.interactiveType && [POLL, POLL_SCORES].includes(this.interactiveType);
    },
    notificationsAvailable() {
      return this.checkModuleIsAvailable("notifications");
    },
    isChatAvailable() {
      return this.checkModuleIsAvailable("groupchat");
    },
    isQuestionsAvailable() {
      return this.checkModuleIsAvailable("questions");
    },
    isStreamAvailable() {
      return this.isBroadcastEnabled;
    },
    showSidebar() {
      return (
        this.isChatAvailable ||
        this.isQuestionsAvailable ||
        this.notificationsAvailable ||
        this.isStreamAvailable
      );
    },
    showPresenterPreviewPlayer() {
      return this.onAirAsLeading;
    },
    showBroadcastPopup() {
      return this.isConferenceType && this.onAir && this.prepared;
    },
    // костыль для мобильных сафари от 12 до 13 версии для решения проблемы скролла
    isSafariIOS12() {
      const [major] = this.$getBrowserVersion();
      return this.$isSafari && this.$isIOS && parseInt(major, 10) === 12;
    },
    broadcastInFullscreenMode() {
      return this.$fullscreen.isFullscreen && this.$fullscreen.group === "broadcast";
    },
    /**
     * Метод проверки было ли закрыто последнее уведомление.
     *
     * @returns {boolean} Закрыто ли уведомление
     */
    isClosedNotif() {
      const lastClosedNotif = localStorage.getItem("lastClosedNotif") || 0;
      return this.lastNotification?.id === +lastClosedNotif;
    },
  },
  watch: {
    availableRoomModules(state) {
      if (state && (this.isChatAvailable || this.isQuestionsAvailable || this.teamChatAvailable)) {
        if (this.messengerTab === "SpeakerQuestions" && !this.isQuestionsAvailable)
          this.setMessengerTab(null);
        if (this.messengerTab === "MainChatList" && !this.isChatAvailable)
          this.setMessengerTab(null);
        if (this.messengerTab === "TeamChatList" && !this.teamChatAvailable)
          this.setMessengerTab(null);

        if (!this.messengerTab) {
          if (this.isChatAvailable) this.setTab("MainChatList");
          else if (this.isQuestionsAvailable) this.setTab("SpeakerQuestions");
          else if (this.teamChatAvailable) this.setTab("TeamChatList");
        }
      }
    },
  },

  async mounted() {
    await this.setRoleMode("event");
    if (this.$isCordova) {
      subscribeNotifications({ roomId: this.room });
    }
    this.$loggers.$sentry.setUser(this.user);
    this.$loggers.$sentry.setTags({
      room: this.room,
      context: "Guest",
    });
    await this.$nextTick();
    this.isLoading = false;
    if (this.room === "1500") {
      this.setYaMetrics();
    }
  },

  updated() {
    if (!this.isChatAvailable && this.isQuestionsAvailable) {
      this.setMessengerTab("SpeakerQuestions");
    } else if (this.isChatAvailable && !this.isQuestionsAvailable) {
      this.setMessengerTab("MainChatList");
    }
  },

  beforeDestroy() {
    if (this.$isCordova) {
      removeOneSignalSubscription(this.room);
    }
    this.setSpeakerQuestionSort(true);
  },
  beforeRouteUpdate(to, from, next) {
    if (!AuthService.getToken()) {
      next("/login");
      return;
    }
    next();
  },
  methods: {
    ...mapMutations(messenger, {
      setMessengerTab: SET_MESSENGER_TAB,
    }),
    // todo: not used
    ...mapMutations({
      setRoleMode: ROLE_SET_MODE,
    }),
    ...mapMutations(questions, {
      setSpeakerQuestionSort: SET_QUESTIONS_SORT,
    }),
    getNotifyMessage(message) {
      if (!message.length) {
        return "";
      }

      return message
        .split("\n")
        .filter(stroke => stroke.length)
        .map(item => `<p style="margin: 0">${item}</p>`)
        .join(" ");
    },
    setTab(tab) {
      this.setMessengerTab(tab);
    },
    /**
     * Проверка включен ли тот или иной модуль в комнате
     *
     * @param moduleKey
     * @returns {boolean}
     */
    checkModuleIsAvailable(moduleKey) {
      return this.availableRoomModules[moduleKey];
    },
    /**
     * Вычисляет и возвращает значения для названий табов в сайдбаре
     *
     * @returns {{chatTabName: string, questionsTabName: string}}
     */
    getTabNames() {
      const navChat =
        this.isChatAvailable &&
        this.navigationItems.find(item => item.route === RoomRouteName.Chat);
      const navQuestions =
        this.isQuestionsAvailable &&
        this.navigationItems.find(item => item.route === RoomRouteName.Questions);
      return {
        chatTabName: (navChat && navChat.text) || this.$t("Room.mainChatList"),
        questionsTabName: (navQuestions && navQuestions.text) || this.$t("Room.speakerQuestions"),
      };
    },
  },
};
</script>

<style lang="scss" module>
.container {
  grid-area: content;
}

.sidebar {
  grid-area: sidebar;

  &__playerWrapper {
    position: relative;
    width: 100%;
    padding-top: calc((9 / 16) * 100%);
    margin-bottom: 10px;

    & > div {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__playerWrapper_centered {
    width: 40vw;
    padding-top: calc((9 / 16) * 40vw);
    margin-right: auto;
    margin-left: auto;
  }

  &__expandBtn {
    position: absolute;
    right: 0;
    display: block;
    width: 32px;
    height: 32px;
    margin-left: 7px;
    cursor: pointer;
    user-select: none;
    border-radius: 50%;

    &::before {
      display: block;
      width: 100%;
      height: 100%;
      font-size: 16px;
      line-height: 32px;
      color: var(--mute-text-color);
      text-align: center;
    }

    &_fixed {
      position: fixed;
      top: 10px;
      right: 10px;
      margin-left: 0;
    }
  }

  &_fullscreen {
    grid-area: content;
  }
}

.features {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 8px;
}

.tabs {
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  background-color: var(--base-bg-color);
  border: 1px solid var(--base-bg-color);
  border-radius: 4px;
}

.tab {
  box-sizing: border-box;
  flex-grow: 1;
  padding: 5px 12px;
  font-size: 16px;
  color: var(--mute-text-color);
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  background-color: #fff;
  transition: all ease 0.3s;

  &_active {
    color: #fff;
    background-color: var(--base-color);
  }

  &:not(:last-child) {
    border-right: solid 1px var(--base-color);
  }
}

.notification {
  overflow: hidden;
  overflow-wrap: break-word;
  background-color: #f8d7da;
  color: #842029;
  margin: 10px 15px;
  padding: 8px 15px 10px;
  border: 1px solid #f5c2c7;
  border-radius: 5px;

  .notificationHeader {
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 15px;
  }
}

.broadcastSettings {
  grid-area: sheet;
}
</style>
