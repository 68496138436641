<template>
  <div></div>
</template>

<script>
import { mapState } from "vuex";
import random from "lodash/random";
import { auth, room } from "@/store/modules/store.namespaces";

const USER_IDLE_TIMEOUT = 180000;
/**
 * Компонент для обработки и фиксации присутствия пользователя в комнате
 *
 * @vue-data {number|null} sendLogTimeoutId - ид таймаута если пользователь ушел из вкладки
 * @vue-data {number|null} pingTimeout - ид таймаута пингования
 * @vue-data {boolean} userAbscent - пользователь отстутствует или нет
 * @vue-data {Function | null} handleDocumentVisibilityChange - обработчик события visibilitychange
 * @vue-data {Function | null} handleWindowUnload - обработчик события unload
 * @vue-data {Function | null} handleWindowBeforeunload - обработчик события beforeunload
 * @vue-data {number|null} timestamp - фиксация текущей даты в формате timestamp
 * @vue-data {object|null} payload - сохраненый payload для корректной отправки события Stop
 * @vue-computed {boolean} available - доступность фиксации присутствия в комнате
 */
export default {
  name: "UserPresenceProvider",
  data() {
    return {
      sendLogTimeoutId: null,
      userAbscent: false,
      handleDocumentVisibilityChange: null,
      handleWindowUnload: null,
      handleWindowBeforeunload: null,
      timestamp: null,
      pingTimeout: null,
      payload: null,
    };
  },
  computed: {
    ...mapState(auth, ["user"]),
    ...mapState(room, {
      room: "roomNumber",
      roomId: "roomId",
    }),
    available() {
      /* roomId должен быть последний для корректного срабатывания watch */
      return this.user.scheduleMainFilter && this.$loggers.$userPresence.available && this.roomId;
    },
  },
  watch: {
    async available() {
      if (
        this.payload &&
        this.payload.roomId !== this.roomId &&
        this.$loggers.$userPresence.available
      ) {
        await this.reset(true);
        this.start();
      } else if (this.$loggers.$userPresence.available) {
        this.start();
      } else {
        this.reset(true);
      }
    },
  },
  mounted() {
    if (this.available) {
      this.start();
    }
  },
  beforeDestroy() {
    this.reset();
  },
  methods: {
    /**
     * Формирует payload для логера
     * Сохраняет в состоянии компонента чтобы переход по комнатам и выход был корректный для учета присутствия
     *
     * @param {number} event - тип события которое произошло
     * @returns {object} - подготовленный объект для логирования
     */
    getLogPayload(event) {
      if (!this.payload) {
        this.payload = {
          login: this.user.login,
          fio: this.user.fio,
          userId: this.user.id,
          roomNumber: this.room,
          roomId: this.roomId,
          audienceId: this.user.scheduleMainFilter,
        };
      }
      return this.$loggers.$userPresence.getPayload(this.payload, event);
    },
    /**
     *  Установка обработчиков событий ухода из вкладки и закрытия или перезагрузки вкладки
     */
    setListeners() {
      this.handleDocumentVisibilityChange = async () => {
        const now = new Date().getTime();
        const userAbscentSafe = this.timestamp ? this.timestamp + USER_IDLE_TIMEOUT < now : false;
        if (document.hidden) {
          this.timestamp = now;
          this.sendLogTimeoutId = setTimeout(async () => {
            await this.$loggers.$userPresence.sendLog(
              this.getLogPayload(this.$loggers.$userPresence.Events.Stop),
            );
            this.userAbscent = true;
          }, USER_IDLE_TIMEOUT);
        } else if (this.userAbscent || userAbscentSafe) {
          await this.$loggers.$userPresence.sendLog(
            this.getLogPayload(this.$loggers.$userPresence.Events.Start),
          );
          this.userAbscent = false;
        } else {
          this.timestamp = null;
          clearTimeout(this.sendLogTimeoutId);
        }
      };

      document.addEventListener("visibilitychange", this.handleDocumentVisibilityChange);

      this.handleWindowBeforeunload = event => {
        event.preventDefault();
        event.returnValue = "";
      };

      window.addEventListener("beforeunload", this.handleWindowBeforeunload, false);

      this.handleWindowUnload = async () => {
        await this.$loggers.$userPresence.sendLog(
          this.getLogPayload(this.$loggers.$userPresence.Events.Stop),
        );
      };

      window.addEventListener("unload", this.handleWindowUnload, false);
    },
    /**
     * Запуска пинга логирования присутствия для более точной фиксации результатов
     */
    runPing() {
      const min = 30;
      const max = 45;
      const timeout = random(min, max) * 60 * 1000;
      this.pingTimeout = setTimeout(() => {
        if (!this.userAbscent) {
          this.$loggers.$userPresence.sendLog(
            this.getLogPayload(this.$loggers.$userPresence.Events.Ping),
          );
        }
        this.runPing();
      }, timeout);
    },
    /**
     * Запускает пинг, навешивает обработчики событий для корректной фиксации присутствия
     */
    start() {
      this.$loggers.$userPresence.sendLog(
        this.getLogPayload(this.$loggers.$userPresence.Events.Start),
      );
      this.runPing();
      this.setListeners();
    },
    /**
     * Сбрасывает пинг, убирает обработчики и очищает таймауты
     * Так же отправляет событие Stop чтобы зафиксировать отсутствие юзера в комнате в любых проявлениях
     *
     * @param {boolean} forcibly - принудительно отправить stop независимо от состояния
     * @returns {Promise<void>}
     */
    async reset(forcibly = false) {
      if (this.available || forcibly) {
        await this.$loggers.$userPresence.sendLog(
          this.getLogPayload(this.$loggers.$userPresence.Events.Stop),
          forcibly,
        );
        this.payload = null;
        if (this.pingTimeout) {
          clearTimeout(this.pingTimeout);
        }
        if (this.sendLogTimeoutId) {
          clearTimeout(this.sendLogTimeoutId);
        }
        document.removeEventListener("visibilitychange", this.handleDocumentVisibilityChange);
        window.removeEventListener("beforeunload", this.handleWindowBeforeunload);
        window.removeEventListener("unload", this.handleWindowUnload);
      }
    },
  },
};
</script>
