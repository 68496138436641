<template>
  <div :class="$style.playerPreview">
    <loader v-if="isLoading" center />
    <ws-icon
      v-else-if="isError"
      class="absolute-center"
      :class="$style.error"
      color="danger"
      light
      xxl
    >
      exclamation-circle
    </ws-icon>
    <div ref="localPlayer" :class="$style.playerPreview__wrapper"></div>
  </div>
</template>

<script>
import flashphonerMixin from "@/components/common/broadcast/mixins/flashphonerMixin";
import Loader from "@/components/common/elements/Loader";
import { OPTION_EXTERNAL_STREAM } from "@/constants/broadcast/broadcast-const";
import WsIcon from "@/components/base/WsIcon";

export default {
  name: "RoomBroadcastPreviewPlayer",
  components: { WsIcon, Loader },
  mixins: [flashphonerMixin],
  props: {
    resolution: {
      type: Object,
      default: () => ({
        width: "320",
        height: "180",
      }),
    },
    constraints: {
      type: Object,
      default: () => ({ audio: true, video: true }),
    },
    onAir: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isError: false,
    };
  },
  computed: {
    previewConstraints() {
      if (!this.constraints) return { audio: true, video: true };
      let { width } = this.resolution;
      let { height } = this.resolution;
      if (this.$isSafariWebRTC || (this.$isIOS && !this.$isSafari)) {
        width = {
          ideal: this.resolution.width,
        };
        height = {
          ideal: this.resolution.height,
        };
      }
      const video = {
        deviceId: {
          exact: this.constraints.video.deviceId,
        },
        label: this.constraints.video.label,
        facingMode: this.constraints.video.facingMode,
        width,
        height,
      };

      if (this.$isSafariWebRTC && this.$isIOS) {
        delete video.width;
        delete video.height;
      }

      return {
        audio: this.constraints.audio,
        video,
      };
    },
  },
  watch: {
    "constraints.video.deviceId": function(val) {
      if (val !== OPTION_EXTERNAL_STREAM) {
        this.init();
      }
    },
    onAir(val, oldVal) {
      if (!val && oldVal) {
        this.init();
      }
    },
  },
  async created() {
    await this.initFlashphoner();
  },
  mounted() {
    if (this.constraints.video.deviceId !== OPTION_EXTERNAL_STREAM) {
      this.init();
    }
  },
  beforeDestroy() {
    this.clearPlayer();
  },
  methods: {
    async init() {
      try {
        this.isLoading = true;
        this.$emit("loading");
        this.clearPlayer();
        await this.$FP.getMediaAccess(this.previewConstraints, this.$refs.localPlayer);
        const localVideo = this.$refs.localPlayer.querySelector("video");
        localVideo.setAttribute("playsinline", "playsinline");
        localVideo.setAttribute("autoplay", "autoplay");
        localVideo.addEventListener("playing", () => {
          this.isLoading = false;
          this.$emit("loaded");
        });
      } catch (e) {
        this.isLoading = false;
        this.isError = true;
        this.$emit("error");
      }
    },
    clearPlayer() {
      this.isError = false;
      const localVideo = this.$refs.localPlayer.querySelector("video");
      if (localVideo) {
        this.stopVideoTracks(localVideo);
        this.$refs.localPlayer.innerHTML = "";
      }
    },
    // TODO Вынести в миксин
    stopVideoTracks(video) {
      if (!video?.srcObject) return;
      video.srcObject.getTracks().forEach(track => {
        track.stop();
        video.srcObject.removeTrack(track);
      });
    },
  },
};
</script>

<style lang="scss" module>
.playerPreview {
  width: 100%;
  height: 100%;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
}

.error {
  font-size: 5em;
}
</style>
