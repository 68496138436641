<template>
  <div
    v-show="!isInteractive || broadcastInFullscreenMode"
    class="room-side-container"
    :class="{
      'room-side-container--closed': !sidebar.expanded,
    }"
  >
    <div class="room-side-container__inner">
      <slot></slot>
      <div
        v-show="!isInteractive || broadcastInFullscreenMode"
        class="room-side-btn icon-arrow-right"
        :title="sideBtnTitle"
        :class="{ 'room-side-btn--closed': !sidebar.expanded }"
        @click="toggleExpanded"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { room } from "@/store/modules/store.namespaces";
import { TOGGLE_SIDEBAR_EXPANDED } from "@/store/modules/common/mutation-types";

export default {
  computed: {
    ...mapState(room, ["sidebar"]),
    isInteractive() {
      return this.$store.state.route.name === `interactive`;
    },
    sideBtnTitle() {
      return this.sidebar.expanded
        ? this.$t("Room.title.closeSidePanel")
        : this.$t("Room.title.openSidePanel");
    },
    broadcastInFullscreenMode() {
      return this.$fullscreen.isFullscreen && this.$fullscreen.group === "broadcast";
    },
  },
  methods: {
    ...mapMutations(room, {
      toggleSidebarExpanded: TOGGLE_SIDEBAR_EXPANDED,
    }),
    toggleExpanded() {
      this.toggleSidebarExpanded();
    },
  },
};
</script>

<style scoped lang="less">
.room-side-container {
  width: 380px;
  background-color: #fff;
  box-sizing: border-box;
  padding-top: 12px;
  overflow: hidden;
  transition: all ease 0.3s;
  border-left: solid 1px #e5e5e5;
  position: relative;

  &__inner {
    display: flex;
    flex: auto;
    flex-direction: column;
    flex-shrink: 0;
    width: 380px;
    height: 100%;
    overflow: hidden;
  }

  &--closed {
    width: 0;
    border-left: 0;
  }

  &--expanded {
    width: 100%;
    overflow-y: auto;
  }
}

.room-side-btn {
  display: block;
  width: 44px;
  height: 44px;
  box-shadow: 0 5px 12px 0 var(--base-shadow-color);
  background-color: #ffffff;
  border: solid 1px var(--base-color);
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  position: fixed;
  top: 50%;
  right: 380px;
  cursor: pointer;
  transform: translate(50%, -50%);
  opacity: 0.5;
  transition: all ease 0.3s;
  z-index: 10;
  @media (max-width: 379px) {
    right: initial;
    left: 0;
    transform: translate(0, 0);
  }
  &:hover {
    opacity: 1;
  }
  &:before {
    display: block;
    font-size: 20px;
    line-height: 42px;
    color: var(--base-color);
    cursor: pointer;
    transition: all ease 0.3s;
    transform: rotate(0deg);
  }
  &--closed {
    right: 0;
    background-color: var(--base-color);
    opacity: 0.7;
    transform: translate(0, -50%);

    &:before {
      transform: rotate(180deg);
      color: #fff;
    }
  }
}
</style>
