<template>
  <smart-modal
    data-testid="userMenuPopup"
    :visible="visible"
    :class="$style.container"
    @close="$emit('close')"
  >
    <template #header>
      <header class="flex align-baseline">
        <landing-lang-selector v-if="options.multilingual" data-testid="langSelector" />
        <ws-button
          data-testid="close"
          icon
          color="default"
          :class="$style.close"
          @click="$emit('close')"
        >
          <ws-icon xxl light color="default">times</ws-icon>
        </ws-button>
      </header>
    </template>
    <template #body>
      <div class="flex flex-column" :class="$style.body">
        <div :class="$style.body__item" class="flex flex-column align-center">
          <div
            :class="[$style.avatar, { [$style.avatar_empty]: !user.avatar }]"
            data-testid="userAvatar"
          >
            <user-avatar :src="user.avatar" :alt="user.fullName" :class="$style.avatar__img" />
          </div>
          <p :class="$style.name" data-testid="userName">
            {{ user.guest ? $t("userPopup.loggedAsGuest") : user.fullName }}
          </p>
          <p v-if="!user.guest" :class="$style.position" class="text-md" data-testid="userPosition">
            {{ user.position }}
          </p>
          <router-link v-slot="{ navigate }" :to="{ name: $options.RoomRouteName.Profile }">
            <ws-button
              v-if="!user.guest"
              :class="$style.profileBtn"
              color="primary"
              data-testid="profileBtn"
              @click="goToProfile(navigate)"
            >
              {{ $t("userPopup.toProfile") }}
            </ws-button>
          </router-link>
        </div>
        <div
          v-if="rooms.length > 1"
          class="flex flex-column"
          :class="$style.body__item"
          data-testid="rooms"
        >
          <p :class="$style.caption" class="text-sm">{{ $t("common.division") }}</p>
          <div :class="$style.rooms">
            <div
              v-for="room in rooms"
              :key="room.number"
              :data-testid="`room${room.number}`"
              :class="[
                $style.room,
                {
                  'flex-center': !room.logo,
                  [$style.room_choosed]: room.selected,
                },
              ]"
              class="flex"
              @click="$emit('room-change', room.number)"
            >
              <div v-show="room.selected" :class="$style.room__choosed" class="flex flex-center">
                <ws-icon color="white" regular>check</ws-icon>
              </div>
              <img v-if="room.logo" :class="$style.room__img" :src="room.logo" :alt="room.number" />
              <span v-else class="text-lg">{{ `#${room.number}` }}</span>
            </div>
          </div>
        </div>
        <div
          class="flex flex-column align-center"
          :class="[$style.body__footer, $style.body__item]"
        >
          <template v-if="options.support">
            <p :class="$style.caption" class="text-sm">{{ $t("techSupport.techSupport") }}</p>
            <a
              href="tel:+78006005368"
              class="text-lg"
              :class="$style.phone"
              data-testid="phoneLink"
            >
              <ws-icon :class="$style.phone_icon" color="primary" light>phone-alt</ws-icon>
              8 (800) 600-53-68
            </a>
            <ws-button
              :class="$style.techSupportBtn"
              text
              color="primary"
              font-size="lg"
              data-testid="techSupportBtn"
              @click="$emit('open-support')"
            >
              <ws-icon light>envelope</ws-icon>
              {{ $t("techSupport.writeToSupportInline") }}
            </ws-button>
          </template>
          <a
            v-else
            :href="wsLink"
            target="_blank"
            :class="$style.supportLink"
            data-testid="supportLink"
            class="flex flex-column align-center text-lg"
          >
            <span>{{ $t("userPopup.moreInfoAt") }}</span>
            <img
              src="@/assets/images/logo_white.png"
              alt="Больше информации на whenspeak.ru"
              :class="$style.supportLink__img"
            />
          </a>
          <ws-button
            v-if="options.feedback"
            :class="$style.feedback"
            lg
            color="primary"
            outlined
            data-testid="feedbackBtn"
            @click="$emit('open-feedback')"
          >
            {{ $t("userPopup.feedback") }}
            <ws-icon light lg>comment</ws-icon>
          </ws-button>
        </div>
      </div>
    </template>
    <template #footer>
      <footer class="flex flex-column" :class="$style.footer">
        <ws-button data-testid="logoutBtn" color="primary" lg outlined @click="$emit('logout')">{{
          $t("userPopup.logout")
        }}</ws-button>
        <p :class="[$style.caption, $style.version]" class="text-sm" data-testid="version">
          {{ $t("common.version") }}
        </p>
      </footer>
    </template>
  </smart-modal>
</template>

<script>
import SmartModal from "@/components/common/modals/SmartModal";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import UserAvatar from "@/components/common/user/UserAvatar";
import LandingLangSelector from "@/components/common/landing/LandingLangSelector";
import { RoomRouteName } from "@/constants/router/router-const";

/**
 * Меню пользователя в комнате в интерфейсе участника
 *
 * @typedef UserMenuOptions
 * @property {boolean} [support=false] - Модуль техподдержки
 * @property {boolean} [multilingual=false] - Модуль переключения языка
 * @property {boolean} [feedback=false] - Модуль опроса "Обратная связь"
 * @type {object}
 *
 * @typedef User
 * @property {string} [avatar=""] - аватар пользователя
 * @property {string} [fullName=""] - Полное имя пользователя
 * @property {string} [position=""] - Должность пользователя
 * @property {boolean} [guest=false] - Гость пользователь или нет
 * @type {object}
 *
 * @vue-prop {UserMenuOptions} options - опции для вывода доступных в комнате блоков управления
 * @vue-prop {Array} rooms - список комнат
 * @vue-prop {User} user - информация о пользователе
 * @vue-event {string} room-change - смена комнаты
 * @vue-event {any} logout - выход из платформы
 * @vue-event {any} open-feedback - открыть форму обратной связи
 * @vue-event {any} open-support - открыть форму тех. поддержки
 */
export default {
  name: "RoomUserMenu",
  RoomRouteName,
  components: {
    SmartModal,
    WsButton,
    WsIcon,
    UserAvatar,
    LandingLangSelector,
  },
  props: {
    options: {
      type: Object,
      default: () => ({
        support: false,
        multilingual: false,
        feedback: false,
      }),
    },
    rooms: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
      default: () => ({
        avatar: "",
        fullName: "",
        position: "",
        guest: false,
      }),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    wsLink() {
      return `https://whenspeak.ru?utm_sourse=rooms&utm_content=${this.$route.params.id}`;
    },
  },
  methods: {
    /**
     * Закрываем меню и редиректим на профиль
     *
     * @param {Function} navigate - функция редиректа
     */
    goToProfile(navigate = () => {}) {
      this.$emit("close");
      navigate(this.$route);
    },
  },
};
</script>

<style lang="scss" module>
.close {
  margin-left: auto;
}

.container {
  --page-nav-height: 0 !important;

  max-width: 300px;
}

.container > [class*="smart-modal__panel"] {
  min-width: 300px;
  height: 85vh;
  max-height: 1080px !important;

  @include mq-tablet {
    min-width: 320px;
  }
}

// .container [class*="smart-modal__panel-body"] {
//   @include reset-scroll;
// }

.avatar {
  flex: 0 1 auto;
  width: 120px;
  height: 120px;
  margin-top: 15px;
  overflow: hidden;
  border-radius: 50%;

  &_empty {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--base-color);
  }

  &__img {
    font-size: 70px;
  }
}

.name {
  margin-top: 20px;
  margin-bottom: 5px;
}

.position {
  color: $gray;
}

.name,
.position {
  text-align: center;
  word-break: break-word;
}

.caption {
  align-self: center;
  margin-top: 15px;
  color: $gray;
}

.version {
  margin-top: 5px;
}

.footer {
  grid-column: span 2;
}

.phone {
  margin-top: 10px;
  color: $default-text-color;

  &_icon {
    margin-right: 5px;
  }
}

.techSupportBtn,
.profileBtn {
  margin-top: 10px;
}

.feedback,
.supportLink {
  margin-top: 15px;
}

.supportLink {
  color: $default-text-color;

  &__img {
    width: 80px;
    height: 59px;
    margin-top: 5px;
    object-fit: contain;
  }
}

.rooms {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  grid-gap: 10px;
  margin-top: 15px;
}

.room {
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid $gray;
  border-radius: 5px;

  &_choosed {
    pointer-events: none;
    cursor: default;
    border-color: var(--base-color);
  }

  &__choosed {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 18px;
    height: 18px;
    background-color: var(--base-color);
    border-radius: 50%;
  }

  &__img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.body {
  height: 100%;

  &__item {
    flex: 1 0 auto;
  }

  &__footer {
    justify-content: flex-end;
  }
}
</style>
